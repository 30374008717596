import React, { Component } from 'react';
import logo from '../../assents/logo_franciele_schuck_advocacia_bk.png';


import './styles.css';

class Welcome extends Component {
    render() {
        return (
            <div className="objetivos">
                <div>
                    <img alt="Logotipo Franciele Schuck Advocacia" className="logo-body" src={logo} />
                    <p>
                        Prestamos os serviços de consultoria, assessoria e direção jurídicas com foco na área cível e previdenciária.
                        Atuamos em Caxias do Sul e realizamos atendimentos online e presencias em todo o Estado do Rio Grande do Sul.
                    </p>
                    <p>
                        O escritório tem como objetivo o atendimento personalizado no qual será apresentada a solução mais adequada a cada caso buscando a satisfação dos nossos clientes.
                    </p>
                    <p>
                        O trabalho ofertado tem como basilares os seguintes valores: ética, segurança e qualidade nos resultados, sigilo profissional, transparência e respeito.
                    </p>
                </div>
                <blockquote>
                    <p>
                        Teu dever é lutar pelo Direito, mas se um dia encontrares o Direito em conflito com a Justiça, luta pela Justiça.
                    </p>
                    <cite>Eduardo Juan Couture</cite>
                </blockquote>
            </div>
        );
    }
}
export default Welcome;