import React from 'react';
import { useForm } from "react-hook-form";
import api from '../../sevices/api'

import './styles.css';
import { useState } from 'react';


function Contact(){ 
    const { handleSubmit, register, errors } = useForm();
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone,setPhone] = useState('');
    const [subjectMatter,setSubjectMatter] = useState('');
    const [messenger, setMessenger] = useState('');
 

    function limpaCampos(){        
        setName('');
        setEmail('');
        setPhone(''); 
        setSubjectMatter('');
        setMessenger(''); 
    };

    async function sendMessenger(e){       
        const data = {
            name, 
            email, 
            phone, 
            subjectMatter, 
            messenger           
        };        
        try 
        {
            const response = await api.post('newMessenger', data);                      
            if(!response.data)
                alert("Erro");
            else
            {
                alert("Sucesso");
            }
        }
        finally
        {
            limpaCampos();
        }        
    };

    return(
        <div id="contato" className="contatos">  
            <h1>Entre em contato</h1>          
            <form onSubmit={handleSubmit(sendMessenger)}>
                <input 
                    type="text" 
                    placeholder="Nome"
                    name="name"
                    value={name}
                    onChange={e => setName(e.target.value)}
                    ref={register({
                        required: true,                           
                      })}
                />
                {errors.name && <p>Nome inválido.</p>}
                <input 
                    type="email" 
                    placeholder="E-mail"
                    name="email"
                    value={email}
                    onChange={e => setEmail(e.target.value) }
                    ref={register({
                        pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                        }
                    })}
                />
                {errors.email && <p>E-mail inválido.</p>}
                <input 
                    type="text" 
                    placeholder="Telefone"
                    name = "phone"
                    value={phone}
                    onChange={e => setPhone(e.target.value) }
                    ref={register({
                        required: true,
                        pattern: {
                            value: /^[0-9]{11}$/i,
                        }
                      })}
                />
                {errors.phone && <p>Telefone deve conter 11 dígitos.</p>}
                <input 
                    type="text" 
                    placeholder="Assunto"
                    name = "subjectMatter"
                    value={subjectMatter}
                    onChange={e => setSubjectMatter(e.target.value) }
                    ref={register({
                        required: true,                            
                    })}
                />
                {errors.subjectMatter && <p>Digite o assunto a ser tratado.</p>}
                <textarea 
                    cols="30" 
                    rows="5" 
                    className="messenger" 
                    placeholder="Mensagem"
                    name = "messenger"
                    value={messenger}
                    onChange={e => setMessenger(e.target.value) }
                    
                    ref={register({
                        required: true,                            
                    })}
                />
                {errors.messenger && <p>Descreva o assunto.</p>}
                <input type="submit" className="button"/>
            </form>                      
        </div> 
    );      
}

export default Contact;