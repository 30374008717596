import React from 'react';
import api from '../sevices/api'


import icon1 from '../assents/icons/001-checklist.png';
import icon2 from '../assents/icons/002-elder.png';
import icon3 from '../assents/icons/003-insured.png';
import icon4 from '../assents/icons/004-savings.png';
import icon5 from '../assents/icons/005-mortgage.png';
import icon6 from '../assents/icons/006-health-insurance.png';
import icon7 from '../assents/icons/008-team.png';
import icon8 from '../assents/icons/010-rating.png';
import icon9 from '../assents/icons/012-stretcher.png';
import icon10 from '../assents/icons/013-investment.png';
import icon11 from '../assents/icons/015-agreement.png';
import icon12 from '../assents/icons/016-newborn-1.png';
import icon13 from '../assents/icons/017-wheelchair.png';
import icon14 from '../assents/icons/018-duplex.png';


function chooseIcon(id){
    switch(id){
        case '1':
            return icon1;
        case '2':
            return icon5;
        case '3':
            return icon3;
        case '4':
            return icon14;
        case '5':
            return icon10;
        case '6':
            return icon4;
        case '7':
            return icon8;
        case '8':
            return icon13;
        case '9':
            return icon2;
        case '10':
            return icon9;
        case '11':
            return icon6;
        case '12':
            return icon7;
        case '13':
            return icon12;    
        case '14':
            return icon11;         
    }
}
var serial = 0;

const icons = [];
function mountIcon(params){
    
    params.forEach(element => {
        icons.push({
            id: serial,
            icon: chooseIcon(element.code),
            title: element.title,
            description: element.description
        });
        serial ++;
    });
}

api.get('icons').then(response => {
    mountIcon(response.data);    
},[]);

export default icons;