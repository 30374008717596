import React from 'react';

import Header from "../Header";
import Welcome from "../Welcome";
import Goals from "../Goals";
import About from "../About";
import Contact from "../Contact";
import Expertise from "../Expertise";

import iLinkedin from '../../assents/icons/linkedin.png';
import iWhatsapp from '../../assents/icons/whatsapp.png';
import iEmail from '../../assents/icons/enviar.png';
import iInstagram from '../../assents/icons/instagram.png';


import './styles.css';

export default function Home(){   
    
    return(
       <div className="conteiner">
           
            <Header/>
            <Welcome/>
            <Goals/>
            <Expertise/>
            <About/>
            <Contact/>

            <footer>
                <p>© Franciele Schuck Advocacia.</p>
		        <ul>
                    <li><a href='https://www.instagram.com/fkadvocacia.adv/'><img alt = "instagram" className="instagram"src={iInstagram} /></a></li>   	
                    <li><a href='https://www.linkedin.com/in/franciele-schuck-23a238181/'><img alt = "linkedin" className="linkedin"src={iLinkedin} /></a></li>   	
                    <li><a href='mailto:franciele.schuck@hotmail.com?subject=Olá, estou entrando em contato a partir do site'><img alt="E-mail"src={iEmail}/></a></li>
                    <li><a href='https://api.whatsapp.com/send?phone=5554991298104&text=Estou%20entrando%20em%20contato%20a%20partir%20do%20site'><img alt="Whatsapp" src={iWhatsapp}/></a></li>
		        </ul>
	        </footer>
       </div> 
       
    );
}