import React, { Component } from 'react';

import './styles.css';

import fotoFran from '../../assents/fran-perfil.jpeg';

class Welcome extends Component{ 
    render(){
        return(
            <div id="sobre" className="sobre">            
                <div className="descricao">
                    <img className="fotoFran" src={fotoFran} alt="Foto Franciele Schuck"/>
                    <div className="blocoTexto">
                        <h1>Franciele Schuck</h1>
                        <p>
                            Bacharela em direito pelo Centro Universitário da Serra Gaúcha, 2016. Inscrita na Ordem dos Advogado do Brasil nº 114523, seção do Rio Grande do Sul.
                        </p>
                        <p>
                            Especialista em Direito da Seguridade Social - Previdenciário e Prática Previdenciária pela Faculdade Legale, 2021.
                        </p>
                        <p>
                            Áreas de atuação: direito cível e direito previdenciário.
                        </p>
                    </div>
                </div>                 
            </div> 
        );      
    }    
}
export default Welcome;