import React, { useState, useEffect } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import { CSSTransition } from "react-transition-group";

import logo from '../../assents/fk_branco.png';

import './styles.css';

export default function Header() {
    const [isNavVisible, setIsNavVisible] = useState(false);
    const [isSmallScreen, setIsSmallScreen] = useState(true);

    const toggleNav = () => {
        setIsNavVisible(!isNavVisible);

    }

    const closedHeader = (event) => {
        setTimeout(function () { setIsNavVisible(false) }, 1000)
    }

    const handleMediaQueryChange = mediaQuery => {
        if (mediaQuery.matches)
            setIsSmallScreen(true);
        else
            setIsSmallScreen(false);
    }

    useEffect(() => {
        const mediaQuery = window.matchMedia('(max-width: 700px)');
        mediaQuery.addListener(handleMediaQueryChange);
        handleMediaQueryChange(mediaQuery);

        return () => {
            mediaQuery.removeListener(handleMediaQueryChange);
        };
    }, []);

    return (
        <header className="header" onMouseLeave={closedHeader}>
            <img alt="Logotipo Franciele Schuck Advocacia" className="logo-header" src={logo} />
            <button onClick={toggleNav} className="burger">
                <span></span>
            </button>
            <CSSTransition
                in={isNavVisible || !isSmallScreen}
                timeout={350}
                classNames="nav-animation"
                unmountOnExit >

                <nav className="nav">
                    <Link to="/#home"
                        className="link"
                        scroll={el => el.scrollIntoView({ behavior: 'smooth', block: 'center' })}
                    >Home</Link>

                    <Link to="/#area-atuacao"
                        className="link"
                        scroll={el => el.scrollIntoView({ behavior: 'smooth', block: 'center' })}
                    >Áreas de Atuação</Link>

                    <Link to="/#sobre"
                        className="link"
                        scroll={el => el.scrollIntoView({ behavior: 'smooth', block: 'center' })}
                    >Sobre</Link>

                    <Link to="/#contato"
                        className="link"
                        scroll={el => el.scrollIntoView({ behavior: 'smooth', block: 'center' })}
                    >Contato</Link>
                </nav>
            </CSSTransition>
        </header>
    );
}

