import React, {useState, useEffect} from 'react';
import api from '../../sevices/api';
import todosIcones from '../../util/mountIcons'
import Popup from "reactjs-popup";

import './styles.css';

export default function Expertise(){ 
    const [icons, setIcons] = useState([]);  

    useEffect(()=>{
         api.get('icons').then(()=>{ 
             setIcons(todosIcones);
        });
    },[]);
    
    return(
    
    <>
    {
       icons.length > 0 && <div id="area-atuacao"className="area-atuacao">            
            <h1 className="titulo">Áreas de Atuação</h1>
            <div className="icons">
                {icons.map(i =>(
                    <Popup key = {i.id}
                        trigger={
                            <article >
                                <img src={i.icon} alt={i.title} />
                                <h1>{i.title}</h1>
                            </article>
                        }
                        position="bottom center"
                        modal
                        closeOnDocumentClick
                        contentStyle={{
                            backgroundImage: 'linear-gradient(to top, #af90684b, #af906833),url("https://ak4.picdn.net/shutterstock/videos/17315704/thumb/1.jpg")',
                            backgroundSize: 'cover',
                            padding:'20px',
                            border:'2px solid black',
                            borderRadius:'8px',
                            textAlign: 'left',
                            width: '80%'
                        }}
                    >
                        <span className="descritivo-atuacao"> {i.description} </span>
                    </Popup>  
                ))}
            </div>                 
        </div>
    }
    </>
    );
}