import React, { Component } from 'react';

import './styles.css';

class Welcome extends Component{ 
    render(){
        return(
            <div id="home" className="home">
            <div className="descritivo-home">
                <p>
                    Serviços de consultoria, assessoria e direção jurídicas.
                </p>
            </div>                
            </div> 
        );      
    }    
}
export default Welcome;